<template>
  <b-card :title="capitalize($tc('employee'))">

    <b-row>
      <b-col cols="12">
        <field-employee
            :model.sync="employeeLocal"
        />
      </b-col>
      <b-col cols="12">
        <field-date
            :model.sync="startLocal"
            name="start"
            :is-required="true"
        />
      </b-col>
      <b-col cols="12">
        <field-date
            :model.sync="endLocal"
            name="end"
            :min-date="startLocal"
            :is-required="true"
        />
      </b-col>
    </b-row>
  </b-card>

</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../../../utils/filter'

import moment from 'moment'
import FieldEmployee from '../../../../components/input/Employee'
import FieldDate from '../../../../components/input/Date'

export default {
  components: {
    FieldEmployee,
    FieldDate
  },
  props: {
    employee: {},
    start: {},
    end: {},
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const employeeLocal = ref(props.employee)
    const startLocal = ref(props.start)
    const endLocal = ref(props.end)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(employeeLocal, val => {
      emit('update:employee', val)
    })
    watch(startLocal, val => {
      emit('update:start', moment(val).format('YYYY-MM-DD'))
    })
    watch(endLocal, val => {
      emit('update:end', moment(val).format('YYYY-MM-DD'))
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      employeeLocal,
      startLocal,
      endLocal,

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>